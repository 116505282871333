<template>
  <input
    type="range"
    ref="input"
    v-model="model"
    class="form-range"
    :style="sliderStyles"
  />
</template>

<script setup lang="ts">
import { VariantOption } from "~/types/types";

interface Props {
  trackFilledVariant?: VariantOption;
  trackVariant?: VariantOption;
  trackHeight?: string | ".25rem";
  thumbVariant?: VariantOption;
  thumbHeight?: string | "1.5rem";
  thumbWidth?: string | "1.5rem";
}

const props = withDefaults(defineProps<Props>(), {
  trackFilledVariant: "secondary",
  trackVariant: "light-4",
  trackHeight: ".25rem",
  thumbVariant: "primary",
  thumbHeight: "1.5rem",
  thumbWidth: "1.5rem",
});

const model = defineModel<string>();

const input = ref<HTMLInputElement>();

const returnNumber = (input: string | number) => {
  return typeof input == "number" ? input : parseInt(input);
};

const minValue = computed(() => {
  return input.value && input.value!.min ? returnNumber(input.value!.min) : 0;
});

const maxValue = computed(() => {
  return input.value && input.value!.max ? returnNumber(input.value!.max) : 1;
});

const inputRange = computed(() => {
  return maxValue.value - minValue.value;
});

const totalCoveragePercent = computed(() => {
  return ((parseInt(model.value!) - minValue.value) / inputRange.value) * 100;
});

const sliderStyles = computed(() => {
  let styles = `
    --rds-track-variant: var(--rds-${props.trackVariant}-rgb);
    --rds-track-filled-variant: var(--rds-${props.trackFilledVariant}-rgb);
    --rds-track-height: ${props.trackHeight};
    --track-fill-left: ${totalCoveragePercent.value}%;
    --track-fill-right: ${totalCoveragePercent.value + 0.01}%;
    --rds-thumb-color: var(--rds-${props.thumbVariant}-rgb);
    --rds-thumb-height: ${props.thumbHeight};
    --rds-thumb-width: ${props.thumbWidth};
  `;
  return styles;
});
</script>

<style scoped lang="scss">
.form-range:hover {
  --range-track-opacity: 1;
}

.form-range {
  --range-track-opacity: 0.8;
  // setting up the gradient that makes it look like part of the slider is filled
  --rds-track-variant-gradient: linear-gradient(
    90deg,
    rgba(var(--rds-track-filled-variant), 1) var(--track-fill-left),
    rgba(var(--rds-track-variant), var(--range-track-opacity))
      var(--track-fill-right)
  );
  width: 100%;
  height: 1rem;
  padding: 0; // Need to reset padding
  appearance: none;
  background-color: transparent;

  &:focus {
    outline: none;
    outline-offset: 1px;

    // Pseudo-elements must be split across multiple rulesets to have an effect.
    &::-webkit-slider-thumb {
      outline: 1px solid var(--rds-dark-3);
      outline-offset: 2px;
    }
    &::-moz-range-thumb {
      outline: 1px solid var(--rds-dark-3);
      outline-offset: 2px;
    }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    width: var(--rds-thumb-width);
    height: var(--rds-thumb-height);
    margin-top: calc(
      (var(--rds-track-height) - var(--rds-thumb-height)) * 0.5
    ); // Webkit specific
    appearance: none;
    background: rgba(var(--rds-thumb-color), 1);
    border: 1rem;
    border-radius: 1rem;

    &:active {
      background: rgba(var(--rds-thumb-color), 1);
    }
  }

  &::-webkit-slider-runnable-track {
    height: var(--rds-track-height);
    color: transparent;
    cursor: pointer;
    background: var(--rds-track-variant-gradient);
    border-color: transparent;
    border-radius: 1rem;
  }

  &::-moz-range-thumb {
    width: var(--rds-thumb-width);
    height: var(--rds-thumb-height);
    appearance: none;
    background: rgba(var(--rds-thumb-color), 1);
    border: 1rem;
    border-radius: 1rem;

    &:active {
      background: rgba(var(--rds-thumb-color), 1);
    }
  }

  &::-moz-range-track {
    height: var(--rds-track-height);
    color: transparent;
    cursor: pointer;
    background: var(--rds-track-variant-gradient);
    border-color: transparent; // Firefox specific?
    border-radius: 1rem;
  }

  &:disabled {
    pointer-events: none;

    &::-webkit-slider-thumb {
      background: rgba(var(--rds-thumb-color), 0.8);
    }

    &::-moz-range-thumb {
      background: rgba(var(--rds-thumb-color), 0.8);
    }
  }
}
</style>
